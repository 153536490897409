:root {
  --amplify-font-family: Source Sans Pro, sans-serif;

  --amplify-text-xxs: 0.75rem;
  --amplify-text-xs: 0.875rem; /* Text */
  --amplify-text-sm: 1rem; /* Form Headers/Text */
  --amplify-text-md: 1.5rem; /* Title Headers */
  --amplify-text-lg: 2rem;
  --amplify-text-xl: 2.5rem;
  --amplify-text-xxl: 3rem;

  --amplify-primary-color: #bd8b13; /* Titles/Text = Cal Poly Gold */
  --amplify-primary-tint: #bd8b13;
  --amplify-primary-shade: #bd8b13;

  --amplify-secondary-color: #154734; /* Buttons/Links/Banners = Cal Poly Green */
  --amplify-secondary-tint: #154734;
  --amplify-secondary-shade: #154734;

  --amplify-tertiary-color: #bd8b13; /* Highlights = Cal Poly Gold */
  --amplify-tertiary-tint: #bd8b13;
  --amplify-tertiary-shade: #bd8b13;

  --amplify-grey: #54585a; /* Cal Poly Dark Gray */
  --amplify-light-grey: #8e9089; /* Cal Poly Light Gray */

  /* App's color palette */
  --calpoly-gold: #ba8c2d;
  --calpoly-green: #244736;
  --medium-green: #406657;
  --light-green: #5a8272;
  --dark-blue: #030f21;
  --medium-blue: #2d4365;
  --light-blue: #24a0ed;
  --light-pink: #ff6464;
  --light-grey: #5d6a73;
  --bright-orange: #ec7348;
}

.font-cp-gold {
  color: var(--calpoly-gold);
}

.font-cp-green {
  color: var(--calpoly-green);
}

.bg-cp-gold {
  background-color: var(--calpoly-gold);
}

.bg-cp-green {
  background-color: var(--calpoly-green);
}

.bg-medium-green {
  background-color: var(--medium-green);
}

.bg-light-green {
  background-color: var(--light-green);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.bg-medium-blue {
  background-color: var(--medium-blue);
}

.bg-light-blue {
  background-color: var(--light-blue);
}

.bg-light-pink {
  background-color: var(--light-pink);
}

.bg-light-grey {
  background-color: var(--light-grey);
}

.bg-bright-orange {
  background-color: var(--bright-orange);
}

/* toastClassName */
.notification-toast {
  color: #000000 !important;
  border-radius: 8px !important;
  background: #ffffff !important;
  border: 5px solid #bd8b13 !important; /* Toast Border = Cal Poly Gold */
}

